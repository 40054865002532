import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React, { Fragment } from "react";
import {
  FaFacebook,
  FaMapMarkerAlt,
  FaPhone,
  FaRegClock,
  FaRegEnvelope
} from "react-icons/fa";
import { formatPhone } from "../utils/formatPhone";
import Button from "./Button";
export const ChildLocation = ({ data }) => {
  const {
    locationInfo: {
      streetAddress,
      city,
      name,
      state,
      zipcode,
      description,
      contact: { phone, email, facebook },
      officeHours,
      salesRep,
      locationImage: {
        altText,
        localFile
      },
    },
  } = data
  const encodedName = name.split(" ").join("%20")

  return (
    <section className="location">
      <section className="contact-block">
        <div className="row">
          <div className="col-1-of-2 u-align-center">
            <aside className="contact-card">
              <h2 className="contact-card__heading">{parse(data.title)}</h2>
              <div className="contact-card__details">
                <FaMapMarkerAlt className="contact-card__details--icon" />
                <a
                  href={`https://www.google.com/maps/dir/?api=1&destination=${encodedName}%20${city}%2c%20${state}&dir_action=navigate&travelmode=driving`}
                  target="_BLANK"
                  rel="noopener noreferrer"
                >
                  {streetAddress} <br /> {`${city}, ${state} ${zipcode}`}{" "}
                </a>
              </div>
              <div className="contact-card__details">
                <FaPhone className="contact-card__details--icon" />
                <a
                  href={`tel:+1${phone}`}
                  className="contact-card__details--phone"
                >
                  {formatPhone(phone)}
                </a>
              </div>
              <div className="contact-card__details">
                <FaRegClock className="contact-card__details--icon" />
                <table className="contact-card__details--hours">
                  <tbody>
                    {officeHours?.map(({ day, hours }) => (
                      <tr key={day}>
                        <td>{parse(day)}</td>
                        <td>{parse(hours)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </aside>
          </div>
          <div className="col-1-of-2">
            <div className="location-map">
              <iframe
                src={`https://www.google.com/maps/embed/v1/place?q=${encodedName}%20${city}%2c%20${state}&zoom=15&key=${process.env.GATSBY_GOOGLE_API_KEY}&attribution_source=Google+Maps+Embed+API&attribution_web_url=https://developers.google.com/maps/documentation/embed/`}
                title="Google map of location"
                width="100%"
                height="350"
                frameBorder="0"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="about-block">
        <div className="wrapper">
          <h2 className="about-block__heading">About Us</h2>
          <GatsbyImage image={getImage(localFile)} className="about-block__lot-img" alt={altText} />
          <div className="row">
            <div className="col-1-of-2">
              <article className="about-block__description">
                {parse(description)}
              </article>
              <div className="about-block__button-box">
                <Button to="/products/" buttonStyle="primary">
                  View Carport Pricing
                </Button>
              </div>
            </div>
            <div className="col-1-of-2">
              <aside className="about-block__salesperson">
                {salesRep &&
                  salesRep.map(
                    ({
                      salesPerson: {
                        name = "",
                        image: {
                          altText: salesPersonAlt,
                          localFile: salesPersonImage
                        },
                      },
                    }) => (
                      <Fragment key={name}>
                        <GatsbyImage
                          image={getImage(salesPersonImage)}
                          className="about-block__salesperson-img"
                          alt={salesPersonAlt} />
                        <div className="about-block__salesperson-info">
                          <h5 className="about-block__salesperson-name">
                            {parse(name)}
                          </h5>
                          <h6 className="about-block__salesperson-role">
                            Retail Sales Consultant
                          </h6>
                          <ul className="about-block__contact-info-list">
                            <li className="about-block__contact-info-list-item">
                              <FaPhone className="about-block__contact-info-list-item--icon" />
                              <a
                                href={`tel+1${phone}`}
                                className="about-block__contact-info-list-item--link"
                              >
                                {formatPhone(phone)}
                              </a>
                            </li>
                            <li className="about-block__contact-info-list-item">
                              <FaRegEnvelope className="about-block__contact-info-list-item--icon" />
                              <a
                                href={`mailto${email}`}
                                className="about-block__contact-info-list-item--link"
                                target="_BLANK"
                                rel="noopener noreferrer"
                              >
                                {email}
                              </a>
                            </li>
                            <li className="about-block__contact-info-list-item about-block__contact-info-list-item--social-group">
                              <a
                                className="about-block__contact-info-list-item--link"
                                target="_BLANK"
                                rel="noopener noreferrer"
                                href={facebook}
                              >
                                <FaFacebook className="about-block__contact-info-list-item--social" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </Fragment>
                    )
                  )}
              </aside>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
