import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import { FaMap, FaMapSigns, FaPhone, FaRegEnvelope } from "react-icons/fa";
import { formatPhone } from "../utils/formatPhone";
import { normalizePath } from "../utils/normalizePath";
import Button from './Button';
export const ParentLocation = ({
  data: {
    wpChildren: { nodes },
  },
}) => {
  return (
    <section className="location-group">
      <div className="row">
        {nodes &&
          nodes.map((node) => {
            const {
              title,
              uri,
              id,
              locationInfo: {
                name,
                contact: { phone, email },
                streetAddress,
                city,
                state,
                zipcode,
                locationImage: {
                  altText,
                  localFile
                },
              },
            } = node
            return (
              <article className="location-card col-1-of-3" key={id}>
                <header className="location-card__header">
                  <GatsbyImage image={getImage(localFile)} alt={altText} />
                </header>
                <h3 className="location-card__title">
                  {parse(name)}{" "}
                  <span className="location-card__title--span">({title})</span>
                </h3>
                <section className="location-card__body">
                  <div className="location-card__contact">
                    <div className="location-card__details">
                      <FaPhone className="location-card__details--icon" />
                      <a
                        className="location-card__details--phone"
                        href={`tel:+1${phone}`}
                      >
                        {formatPhone(phone)}
                      </a>
                    </div>
                    <div className="location-card__details ">
                      <FaMap className="location-card__details--icon" />
                      <p className="location-card__details--address">
                        {streetAddress}
                        <br />
                        {city}, {state} {zipcode}
                      </p>
                    </div>
                    <div className="location-card__details ">
                      <FaRegEnvelope className="location-card__details--icon" />
                      <a
                        className="location-card__details--email"
                        href={`mailto:${email}`}
                        dangerouslySetInnerHTML={{
                          __html: `${email}`,
                        }}
                        target="_BLANK"
                        rel="noopener noreferrer"
                      />
                    </div>
                  </div>
                </section>
                <div className="location-card__footer">
                  <Button buttonStyle="primary" small to={normalizePath(uri)}>
                    <FaMapSigns />
                    Visit Location
                  </Button>
                </div>
              </article>
            );
          })}
      </div>
    </section>
  );
}
