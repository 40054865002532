import { graphql } from "gatsby"
import React from "react"
import { ChildLocation } from "../../components/ChildLocation"
import Layout from "../../components/Layout"
import PageTitleSection from "../../components/pagebuilder-parts/PageTitleSection"
import { ParentLocation } from "../../components/ParentLocation"
import SEO from "../../components/SEO"
const Location = ({ data: { location: page }, location }) => {
  const {
    title,
    parentId,
    locationInfo: { name, description },
  } = page
  const pageTitle =
    parentId !== null ? `${title} Sales Lot` : `${title} Sales Locations`

  return (
    <Layout>
      <SEO title={pageTitle} description={description || pageTitle} />
      <PageTitleSection data={{ title: pageTitle }} location={location} />
      {parentId !== null ? (
        <ChildLocation data={page} />
      ) : (
        <ParentLocation data={page} />
      )}
    </Layout>
  )
}

export default Location

export const LocationQuery = graphql`query SingleLocation($id: String!) {
  location: wpLocation(id: {eq: $id}) {
    ...SharedLocationFields
    parentId
    uri
    locationInfo {
      name
      officeHours {
        day
        hours
      }
      description
      salesRep {
        ... on WpSalesPerson {
          salesPerson {
            featured
            name
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
    wpChildren {
      nodes {
        ... on WpLocation {
          uri
          ...SharedLocationFields
        }
      }
    }
  }
}
`
